<template>
<div class="step__body">
  <div class="row mb-3">
    <component v-for="option in defaultOptions" :key="option.id" :option="option" :is="option.type + 'Option'" />
  </div>
</div>
</template>
<script>
import { computed } from '@vue/reactivity'
import { useStore } from 'vuex'
import SelectOption from './selectOptionScreen'
export default {
  name: 'ColorScreen',
  components: {
    SelectOption
  },
  setup () {
    const store = useStore()
    const step = computed(() => {
      return store.getters['configurator/currentStep']
    })
    const options = computed(() => {
      return store.getters['configurator/currentOptions']
    })
    const defaultOptions = computed(() => {
      const option = options.value.filter(o => o.group === 'default')
      return option
      // return options.value.filter(o => o.group === 'default')
    })
    return {
      step,
      options,
      defaultOptions
    }
  }
}
</script>
